import axios from "axios";

export default axios.create({
    baseURL: "https://xapi.paygen.in/api",
    // baseURL: "http://3.109.197.3:3000/api",
    // baseURL: "http://1796-183-82-104-227.ngrok",
    headers: {
        "Content-type": "application/json",
    },
});
